<template>
    <layout>
        <template slot="content">
            <h3>
                Главная страница Фестиваль "Благость"
            </h3>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-card
                        class="box-card"
                        shadow="hover"
                    >
                        <div
                            slot="header"
                            class="clearfix"
                        >
                            <span>Card name</span>
                            <el-button
                                style="float: right; padding: 3px 0"
                                type="text"
                            >
                                Operation button
                            </el-button>
                        </div>
                        <p>
                            Lorem ipsum
                        </p>
                        <p>
                            Lorem ipsum
                        </p>
                        <p>
                            Lorem ipsum
                        </p>
                    </el-card>
                </el-col>

                <el-col :span="12">
                    <el-card
                        class="box-card"
                        shadow="hover"
                    >
                        <div
                            slot="header"
                            class="clearfix"
                        >
                            <span>Card name</span>
                            <el-button
                                style="float: right; padding: 3px 0"
                                type="text"
                            >
                                Operation button
                            </el-button>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet
                        </p>
                        <p>
                            Lorem ipsum
                        </p>
                    </el-card>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-card
                        class="box-card"
                        shadow="hover"
                    >
                        <div
                            slot="header"
                            class="clearfix"
                        >
                            <span>Card name</span>
                            <el-button
                                style="float: right; padding: 3px 0"
                                type="text"
                            >
                                Operation button
                            </el-button>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet
                        </p>
                        <p>
                            Lorem ipsum
                        </p>
                    </el-card>
                </el-col>

                <el-col :span="12">
                    <el-card
                        class="box-card"
                        shadow="hover"
                    >
                        <div
                            slot="header"
                            class="clearfix"
                        >
                            <span>Card name</span>
                            <el-button
                                style="float: right; padding: 3px 0"
                                type="text"
                            >
                                Operation button
                            </el-button>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet
                        </p>
                        <p>
                            Lorem ipsum
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet
                        </p>
                        <p>
                            Lorem ipsum
                        </p>
                    </el-card>
                </el-col>
            </el-row>
        </template>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Layout';
import breadcrumbs from '@/mixins/breadcrumbs';

export default {
    name: 'Home',
    components: {
        Layout
    },
    mixins: [
        breadcrumbs
    ],
    computed: {
        $breadcrumbs() {
            return [];
        }
    }
};
</script>
